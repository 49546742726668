import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
const ACCESS_TOKEN = 'token';
const api_url = {
  'product': 'https://api.music.anasgchina.cn/api',
  'dev': 'https://yuedashi202407.test/api'
};
let baseURL;

if (process.env.NODE_ENV === 'development') {
  baseURL = api_url.dev;
} else {
  baseURL = api_url.product;
}

const request = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  },
  timeout: 60000
});
export { baseURL };
request.interceptors.request.use(config => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  token && (config.headers[ACCESS_TOKEN] = token);
  return config;
});
request.interceptors.response.use(response => {
  const err_msg = response.data.msg || '服务器错误';

  if (response.data.code === -9999) {
    _Message.error(err_msg);

    setTimeout(() => {
      location.href = '/login';
    }, 1000);
    return;
  }

  return response.data;
});
export default request;